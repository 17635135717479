import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import { assetUrl } from "../../config";
import DefaultMbFeatured from "../../../assets/img/defaultBlogMbFeatured.jpg";
import DefaultMb from "../../../assets/img/defaultBlogMb.jpg";
import WebP from "../global/WebP";

export default function BlogMinibanner({ post, isFeatured }) {
  const featureStyle = isFeatured ? "featured" : "";
  let postImage = isFeatured ? DefaultMbFeatured : DefaultMb;

  if (post?.image) {
    postImage = `${assetUrl}${post.image}`;
  }

  return (
    <Link
      data-testid={`blog-minibanner-${post.id}`}
      to={`${routeCodes.BLOG}${post.id}`}
      className={`blog-minibanner ${featureStyle}`}
    >
      <div className="blog-minibanner-image">
        <WebP secret={postImage} />
      </div>
      <div className="blog-minibanner-text">
        <div className="blog-minibanner-title">{post.title}</div>
        <div className="blog-minibanner-desc">{post.briefDescription}</div>
        <div className="more">{getString("navigation.more.0")}</div>
      </div>
    </Link>
  );
}

BlogMinibanner.propTypes = {
  post: PropTypes.object.isRequired,
  isFeatured: PropTypes.bool,
};

BlogMinibanner.defaultProps = {
  isFeatured: false,
};
