import React from "react";
import PropTypes from "prop-types";
import Modal from "./modal";
import { getString } from "../../utilities";

export default function OKModal({ button, children, close, doShow, title }) {
  const header = title && title.length > 0 ? <div className="modal-content-header">{title}</div> : null;

  return (
    <Modal doShow={doShow} closeOnEscape={close}>
      {header}
      {children}
      <button type="button" className="button-flat-color pt-green" onClick={close}>
        {button}
      </button>
    </Modal>
  );
}

OKModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  button: PropTypes.string,
};

OKModal.defaultProps = {
  button: getString("ok"),
  children: null,
};
