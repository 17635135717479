import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import SubscriptionCard from "../subscribe/subscriptionCard";
import PlansPromoDescription from "../global/plansPromoDescription";
import PlansPromoDisclaimer from "../global/plansPromoDisclaimer";

export default function FamilyPlans({ plan, setPlan, setPlanAmount, planData, promo }) {
  return (
    <div className="wrapper plans-wrapper family">
      <div className="plans-title">{getString("subscribe.plans.family.title.1")}</div>

      <PlansPromoDescription promo={promo} pRole="family" />

      <div className="plans-subs">
        <SubscriptionCard
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData}
          subscription="m1"
        />
        <SubscriptionCard
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData}
          subscription="m6"
        />
        <SubscriptionCard
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData}
          subscription="m12"
          isBest={true}
        />
      </div>

      <PlansPromoDisclaimer promo={promo} pRole="family" />
    </div>
  );
}

FamilyPlans.propTypes = {
  plan: PropTypes.string,
  setPlan: PropTypes.func.isRequired,
  setPlanAmount: PropTypes.func.isRequired,
  planData: PropTypes.object.isRequired,
  promo: PropTypes.object,
};

FamilyPlans.defaultProps = {
  plan: undefined,
  promo: undefined,
};
