import React, { useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormContext } from "react-hook-form";
import { useMutation } from "urql";

import { getLangCode, language, recaptchaSiteKey } from "../../config";
import { metaDefault } from "../../config/meta";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest } from "../../utilities";
import AccountFields from "./accountFields";
import PaymentFields from "./paymentFields";
import StudentFields from "../global/studentFields";
import ClassroomPlans from "./classroomPlans";
import { submitError, subscribeSubmit } from "../../utilities/subscribe";
import NotAppliedModal from "../global/notAppliedModal";
import SubscribeConfirm from "../global/subscribeConfirm";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function ClassroomForm() {
  const [plan, setPlan] = useState("m12");
  const [total, setTotal] = useState(0);
  const [hasPromoNotApplied, setHasPromoNotApplied] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNotApplied, setGiftCardNotApplied] = useState(false);
  const [notAppliedModal, setNotAppliedModal] = useState({ show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);

  const { success, classroomData: planData, submitting, setSubmitting, promo, setPromo } = useOutletContext();

  const {
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const location = useLocation();

  const stripe = useStripe();
  const elements = useElements();

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify, accessName, accessCode } = getValues();

    const myVariables = {
      email: email.trim(),
      emailVerify: emailVerify.trim(),
      password,
      passwordVerify,
      billingPlan: planData[plan].planId,
      accessName,
      accessCode,
      giftCards,
    };

    if (promo && promo.shortname) {
      myVariables.promoCode = promo.shortname;
    }

    return myVariables;
  };

  /* ***Need to check subscription fee/cost vs. giftcard applied total*** */
  const { planId, amount, name } = planData[plan];

  let finalAmount = amount;

  if (promo) {
    if (promo.amountOff) {
      finalAmount = amount - promo.amountOff;
      if (finalAmount < 0) finalAmount = 0;
    }

    if (promo.percentOff) {
      finalAmount = amount - amount * promo.percentOff;
    }
  }

  const creditNotRequired = finalAmount < total;
  const action = "Classroom_Form_Submission";

  const onSignupCompleted = (data) => {
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      cardElement.clear();
    }

    success(data, { planId, amount, name }, { invoiceId: data?.signup?.invoiceId });
    setSubmitErrors(null);
  };

  const onSignupError = (error) => {
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [result, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: loading } = result;

  const onRecaptchaCompleted = async (data) => {
    const myVariables = getVariables();
    const cardElement = elements.getElement(CardElement);
    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement,
      mutation: (variables) =>
        signup(variables).then((result) => handlePostRequest(result, onSignupCompleted, onSignupError)),
      creditNotRequired,
      creditComplete,
      action,
      stripe,
      myVariables,
      plan: { planId, amount, name },
      setSubmitting,
    });
  };

  const onRecaptchaError = (error) => {
    console.log("recaptcha error: ", error);
    submitError(error, setSubmitErrors, null);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: `${getString("join.3")} • ${getString("subscribe.plans.classroom.title.2")}`,
      })}

      <ClassroomPlans plan={plan} setPlan={setPlan} planData={planData} promo={promo} />

      <form
        onSubmit={handleSubmit(() => {
          if (!submitting) {
            if (giftCardNotApplied) {
              // there is a giftcard field filled out but not applied
              // modal call to action to apply giftcard
              setNotAppliedModal({ show: true, type: "giftCard" });
            } else if (hasPromoNotApplied) {
              // the promo code field is filled out but not applied
              // modal call to action to apply promo

              setNotAppliedModal({ show: true, type: "promo" });
            } else {
              setConfirmModal((prev) => !prev);
            }
          }
        })}
      >
        <div className="wrapper padded">
          <div className="container-small">
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <h2>{getString("subscribe.create")}</h2>

            <AccountFields errors={errors} submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />
            <StudentFields submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />
            <PaymentFields
              total={total}
              setTotal={setTotal}
              setGiftCardNotApplied={setGiftCardNotApplied}
              giftCards={giftCards}
              setGiftCards={setGiftCards}
              errors={errors}
              hasPromoNotApplied={hasPromoNotApplied}
              setHasPromoNotApplied={setHasPromoNotApplied}
              doesNotRequireCredit={creditNotRequired}
              promo={promo}
              setPromo={setPromo}
              planGroup="classroom"
              setCreditComplete={setCreditComplete}
              submitErrors={submitErrors}
              setSubmitErrors={setSubmitErrors}
              isNewSubscriber
            />

            <button type="submit" className="button-flat-color pt-green" disabled={submitting}>
              {getString("subscribe.submit")}
            </button>

            <div className="subscribe-terms">
              <p>
                {getString("subscribe.terms", {
                  replace: [
                    getLangCode() === language.la ? getString("subscribe.title.1") : getString("subscribe.title.0"),
                  ],
                  html: true,
                })}
              </p>
              <p>{getString("recaptcha", { html: true })}</p>
            </div>
          </div>
        </div>
      </form>

      <SubscribeConfirm
        doShow={confirmModal}
        submit={() => {
          setConfirmModal((prev) => !prev);
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
              recaptchaVerify({ token, action, verify: true });
            });
          });
        }}
        cancel={() => {
          setConfirmModal((prev) => !prev);
        }}
        planData={{
          title: getString("subscribe.plans.classroom.title.2"),
          plan,
          amount,
          finalAmount,
          promo,
          creditNotRequired,
          giftCards: { cards: giftCards, total },
        }}
      />

      <NotAppliedModal
        doShow={notAppliedModal.show}
        type={notAppliedModal.type || "giftCard"}
        close={() => {
          setNotAppliedModal({ show: false });
        }}
      />

      {(loading || recaptchaLoading || submitting) && (
        <LoadingAnim position="fixed" className="background-transparent-white">
          <h4>{getString("subscribe.loading")}</h4>
        </LoadingAnim>
      )}
    </>
  );
}
