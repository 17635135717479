import React from "react";
import PropTypes from "prop-types";

export default function ErrorMessage({ error, children }) {
  const renderMessage = () => {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      if (error.graphQLErrors.length === 1) {
        return <p className="submit-error">{error.graphQLErrors[0].message}</p>;
      }
      const errorList = error.graphQLErrors.map((e) => <li key={e.message}>{e.message}</li>);
      return <ul className="submit-error">{errorList}</ul>;
    }

    return <p className="submit-error">{error.message.replace("[Network]", " ").trim()}</p>;
  };

  return (
    <div className="submit-error-container">
      {renderMessage()}
      {children}
    </div>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.any,
  error: PropTypes.any,
};

ErrorMessage.defaultProps = {
  children: null,
  error: undefined,
};
