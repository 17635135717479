import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import Modal from "./modal";
import ModalClose from "./modalClose";
import { CHECK_PASSWORD_QUERY } from "../../graphql/queries";
import { getString } from "../../utilities";
import OptionallyDisplayed from "./optionallyDisplayed";
import TextViewHook from "./textViewHook";
import ErrorMessage from "./errorMessage";
import LoadingAnim from "./loadingAnim";
import { getMaxLengthValidator } from "../../utilities/forms/rules";
import { TEXT_FIELD_CHAR_LIMIT } from "../../config";
import { useLazyQuery } from "../../hooks";

export default function PasswordConfirmModal({ submit, close, doShow, children, header }) {
  const passwordString = getString("forms.password.0");

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const [submitErrors, setSubmitErrors] = useState(null);

  useEffect(() => {
    if (doShow) {
      document.getElementById("passwordConfirmModal").focus();
    }
  }, [doShow]);

  const onCompleted = (data) => {
    const { checkPasswordForToken } = data;
    reset({ passwordConfirmModal: "" });
    if (checkPasswordForToken && checkPasswordForToken.success) {
      submit();
    }
  };

  const onError = (error) => {
    setSubmitErrors(error);
  };

  const { executeQuery: checkPasswordForToken, loading } = useLazyQuery(CHECK_PASSWORD_QUERY, onCompleted, onError);

  const resetClose = () => {
    reset({ passwordConfirmModal: "" });
    setSubmitErrors(null);

    close();
  };

  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  return (
    <Modal doShow={doShow}>
      <OptionallyDisplayed doDisplay={loading}>
        <LoadingAnim position="absolute" className="background-white">
          <h4>{getString("checkPass")}</h4>
        </LoadingAnim>
      </OptionallyDisplayed>

      <ModalClose close={resetClose} />
      <div className="modal-content-header">{header}</div>

      <OptionallyDisplayed doDisplay={!!submitErrors}>
        <ErrorMessage error={submitErrors} />
      </OptionallyDisplayed>

      <form
        onSubmit={handleSubmit((data) => {
          if (!loading) {
            // reset if errors are present
            onChange();
            checkPasswordForToken({ password: data.passwordConfirmModal });
          }
        })}
      >
        <FormProvider register={register}>
          {children}

          <TextViewHook
            name="passwordConfirmModal"
            type="password"
            placeholder={passwordString}
            label={passwordString}
            errors={errors}
            rules={{
              required: getString("forms.errorMessages.isRequired.0", { replace: [passwordString] }),
              maxLength: getMaxLengthValidator(passwordString, TEXT_FIELD_CHAR_LIMIT),
            }}
            onChange={onChange}
          />

          <div>
            <button type="submit" className="button-flat-color pt-green ok">
              {getString("submit.0")}
            </button>
            <button type="button" className="button-flat-color pt-orange" onClick={resetClose}>
              {getString("cancel.1")}
            </button>
          </div>
        </FormProvider>
      </form>
    </Modal>
  );
}

PasswordConfirmModal.propTypes = {
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  doShow: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  header: PropTypes.string,
};

PasswordConfirmModal.defaultProps = {
  header: getString("forms.password.0"),
};
