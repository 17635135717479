import React from "react";
import PropTypes from "prop-types";
import { useLocation, NavLink } from "react-router-dom";

import { getString } from "../../utilities";
import PromoBurst from "./promoBurst";
import routeCodes from "../../config/routeCodes";

export default function PlanButton({ color, promo, to, subType }) {
  const location = useLocation();
  const isDefault =
    [routeCodes.SUBSCRIBE_FREE, routeCodes.SUBSCRIBE_CLASSROOM, routeCodes.SUBSCRIBE_SCHOOL].every(
      (plan) => location.pathname !== plan,
    ) && to === routeCodes.SUBSCRIBE_FAMILY;

  const renderPromo = () => {
    if (to === routeCodes.SUBSCRIBE_FAMILY) {
      return <PromoBurst promo={promo} />;
    }
    return null;
  };

  return (
    <NavLink
      type="button"
      className={({ isActive }) =>
        `planbutton button-flat-color round ${color} ${isActive || isDefault ? "active" : ""}`
      }
      end
      to={to}
    >
      {getString(`subscribe.plans.${subType}.title.0`)}
      {renderPromo()}
    </NavLink>
  );
}

PlanButton.propTypes = {
  color: PropTypes.string.isRequired,
  promo: PropTypes.object,
  to: PropTypes.string.isRequired,
  // Free, family or classroom account
  subType: PropTypes.string.isRequired,
};

PlanButton.defaultProps = {
  promo: null,
};
