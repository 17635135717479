import React, { createRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { metaType } from "../../config/meta";
import { getString, offset, sortedArrayByItem } from "../../utilities";
import { getGamesByGradeNumber } from "../../utilities/games";
import GradeNav from "../global/gradeNav";
import Leader from "../ads/leader";
import NotFound from "../global/notFound";
import GradeCategories from "./gradeCategories";
import CategoryNav from "./categoryNav";
import FeatureList from "./featureList";
import GradesArt from "./GradesArt";
import grades from "../../config/grades";
import { gradeCategories } from "../../config/categories";
import { hiddenCategoriesSelector } from "../../redux/slices/loginSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { getAbcyaUrl } from "../../config";
import { routeCodes } from "../../config/routes";
import GradeInfo from "./GradeInfo";
import AppleIcon from "../../../assets/svg/apple.svg";

export default function Grade() {
  const infoId = "gradeInfo";
  const location = useLocation();
  const { url, cat } = useParams();

  const hiddenCategories = useSelector(hiddenCategoriesSelector);
  const activeCategories = gradeCategories.filter(
    (category) => !hiddenCategories.map((hiddenCat) => hiddenCat.id).includes(category.id),
  );
  const catRefs = activeCategories.map(() => createRef());

  const { games, lists } = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);

  const [grade, setGrade] = useState(grades.find((grade) => grade.localeUrl === url || grade.url === url));
  const [gradeGames, setGradeGames] = useState(null);
  const [featuredList, setFeaturedList] = useState(null);
  const [docHeight, setDocHeight] = useState(window.document.body.offsetHeight);
  const [isDrawn, setIsDrawn] = useState(false);

  const initGrade = () => {
    setGradeGames(sortedArrayByItem(getGamesByGradeNumber(games, grade.number), "fullname"));

    const gradeFeatureList = lists
      .filter((l) => l.group === "grade-feature")
      .find((l) => l.shortname.endsWith(`-${grade.localeUrl || grade.url}`));

    if (gradeFeatureList) {
      let items = [];
      gradeFeatureList.items.forEach((item) => {
        const game = games.find((g) => g.id === item?.game);
        if (game) {
          items.push({ ...item, game });
        }
      });
      if (items && items.length > 0) {
        items = sortedArrayByItem(items, "order");
        setFeaturedList({ ...gradeFeatureList, items });
      } else {
        setFeaturedList(null);
      }
    } else {
      setFeaturedList(null);
    }
  };

  useEffect(() => {
    if (!loading && games && grade && grades && lists) {
      initGrade();
    }
  }, [grade, loading, games, lists, hiddenCategories]);

  useEffect(() => {
    setGrade(grades.find((grade) => grade.localeUrl === url || grade.url === url));
  }, [url]);

  useEffect(() => {
    const pageStable = async () => {
      await setTimeout(async () => {
        const nextHeight = window.document.body.offsetHeight;
        if (nextHeight === docHeight) {
          setIsDrawn(true);
        } else {
          setDocHeight(nextHeight);
        }
      }, 100);
    };

    pageStable();
  }, [docHeight]);

  const getHeaderHeight = () => {
    const header = document.getElementById("header");
    const cookieBanner = document.getElementById("cookiebanner");

    const headHeight = header.offsetHeight;
    const cbHeight = cookieBanner ? cookieBanner.getBoundingClientRect().height : 0;

    return headHeight + cbHeight;
  };

  const scrollToCat = (index) => {
    const contentPosition = offset(catRefs[index].current).top - getHeaderHeight();
    setTimeout(() => {
      window.scroll({ left: 0, top: contentPosition });
    }, 200);
  };

  const scrollAfterRender = (index) => {
    return (node) => {
      if (node) {
        catRefs[index].current = node;
      }
    };
  };

  const scrollToInfo = () => {
    const infoPosition = offset(document.getElementById(infoId)).top - getHeaderHeight();
    window.scroll({ left: 0, top: infoPosition });
  };

  useEffect(() => {
    if (catRefs.every((ref) => !!ref.current) && isDrawn && cat) {
      const index = activeCategories
        .map((category) => (category.localeShortname ? category.localeShortname : category.shortname))
        .indexOf(cat);
      if (index !== -1) {
        scrollToCat(index);
      }
    }
  }, [cat, isDrawn, catRefs]);

  if (grade) {
    const gradeStyle = `gr-${grade.url}`;
    const gradeTitle =
      grade.number === -1 ? grade.shortname : getString("grades.title", { replace: [grade.shortname] });

    const canonicalHrefString = `${getAbcyaUrl()}${routeCodes.GRADES}${grade.localeUrl || grade.url}`;

    return (
      <>
        {metaType("WebSite", grade, location.pathname, [{ rel: "canonical", href: canonicalHrefString }])}

        <GradeNav />

        <div className="wrapper grade">
          <div className={`grade-title ${gradeStyle}`}>{gradeTitle}</div>

          <div className="grade-nav-standards">
            <button
              type="button"
              className="button-flat-color round dimensional cyan auto standards"
              onClick={() => {
                scrollToInfo();
              }}
            >
              <AppleIcon className="inline-icon" />
              {getString("educatorInfo")}
            </button>

            {/* <StandardsButton url={grade.localeUrl || grade.url} number={grade.number} /> */}
          </div>
          <div className="grade-nav-categories">
            <CategoryNav url={grade.localeUrl || grade.url} categories={activeCategories} />
          </div>

          <div className="grade-nav-art-container">
            <GradesArt url={grade.url} longname={grade.longname} />
          </div>
        </div>

        <Leader className="green" />

        <FeatureList data={featuredList} gradeNumber={grade?.number} />

        <GradeCategories
          grade={grade}
          games={gradeGames}
          categories={activeCategories}
          onRefRender={scrollAfterRender}
        />

        <GradeInfo grade={grade} infoId={infoId} />
      </>
    );
  }

  // grade will end up "undefined"
  // data is retreived and set into state
  // url param cat provided isn't a grade we offer (not found in data)
  // ie: abcya.com/grades/9
  return <NotFound item={getString("grade.1")} />;
}
