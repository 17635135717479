import React from "react";
import PropTypes from "prop-types";
import { getString, purifiedParsedString } from "../../utilities";

export default function GameInfo({ infoRef, gameInfoData }) {
  if (gameInfoData) {
    return (
      <div ref={infoRef} className="container-medium educator-info">
        <div data-testid="edu-info-title" className="educator-info-title">
          {getString("educatorInfo")}
        </div>

        {Object.keys(gameInfoData).map((infoKey, index) => {
          const categoryClass = `educator-info-category-${index + 1}`;
          const infoContent = purifiedParsedString(gameInfoData[infoKey]);
          const infoTitle = getString(`game.educatorInfo.${infoKey}`);

          return (
            <div key={infoKey} className={`educator-info-category ${categoryClass}`}>
              <div className="educator-info-category-title">{infoTitle}</div>
              <div className="educator-info-category-info">{infoContent}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

GameInfo.propTypes = {
  gameInfoData: PropTypes.object,
  infoRef: PropTypes.object.isRequired,
};

GameInfo.defaultProps = {
  gameInfoData: null,
};
