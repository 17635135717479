import React from "react";
import PropTypes from "prop-types";
import { getString, currencyConvert } from "../../utilities";

export default function Redeemed({ amount, email, redeemAnother, phoneHome }) {
  return (
    <>
      <h2>{getString("redeem.card.redeemed.title")}</h2>

      <div>{getString("redeem.card.redeemed.message", { replace: [currencyConvert(amount, "us"), email.trim()] })}</div>

      <div className="redeem-card-form-buttons">
        <button type="button" className="button-flat-color green" onClick={redeemAnother}>
          {getString("redeem.card.redeemed.cta")}
        </button>

        <button type="button" className="button-flat-color orange" onClick={phoneHome}>
          {getString("done.0")}
        </button>
      </div>
    </>
  );
}

Redeemed.propTypes = {
  amount: PropTypes.number,
  email: PropTypes.string.isRequired,
  redeemAnother: PropTypes.func.isRequired,
  phoneHome: PropTypes.func.isRequired,
};

Redeemed.defaultProps = {
  amount: undefined,
};
