import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import MiniBanner from "../global/miniBanner";
import { getString } from "../../utilities";
import { adData, contentTypes } from "../../config";
import { routeCodes } from "../../config/routes";
import Leader from "../ads/leader";
import { mobile } from "../../utilities/detection";

export default function GradeCategories({ grade, games, categories, onRefRender }) {
  const navigate = useNavigate();
  const { cat: currentCat } = useParams();

  const gameLists = useMemo(() => {
    const categoryDict = categories.reduce((currentDict, category) => {
      const nextDict = { ...currentDict, [category.shortname]: [] };
      return nextDict;
    }, {});

    if (games && games.length > 0) {
      let ti = 0;
      games.forEach((game) => {
        game.categories.forEach((gameCat) => {
          if (Object.prototype.hasOwnProperty.call(categoryDict, gameCat)) {
            ti++;
            categoryDict[gameCat].push(
              <MiniBanner
                key={game.id}
                type={contentTypes.game}
                title={game.fullname}
                path={game.localeShortname || game.shortname}
                image={game.miniBanner ? game.miniBanner : null}
                grades={game.grades}
                isMobile={game.mobileActive}
                isSearchable={game.searchable}
                hasNoScroll={true}
                clickHandler={`${routeCodes.GAMES}${game.localeShortname || game.shortname}`}
                tabIndex={String(ti)}
              />,
            );
          }
        });
      });
    }
    return categoryDict;
  }, [games]);

  if (games && games.length > 0) {
    const categoryLists = [];
    for (let i = 0; i < categories.length; ++i) {
      const category = categories[i];
      const catActive =
        currentCat && (category.localeShortname === currentCat || category.shortname === currentCat) ? " open" : "";

      categoryLists.push(
        <div
          ref={onRefRender(i)}
          key={category.id}
          className={`grade-category-games ${category.shortname}${catActive}`}
          data-testid="grade-category-element"
        >
          <div
            className="category-btn"
            onClick={() => {
              navigate(
                `${routeCodes.GRADES}${grade.localeUrl || grade.url}/${
                  category.localeShortname ? category.localeShortname : category.shortname
                }`,
              );
            }}
          >
            {category.name}
          </div>

          <div className="category-header">
            {getString(`grades.categories.${category.shortname}`, { replace: [grade.longname] })}
          </div>

          <div className="container grade-games-container">{gameLists[category.shortname]}</div>
        </div>,
      );
      if (!mobile && i % 2 === 0 && i < categories.length - 1) {
        categoryLists.push(
          <div data-testid="grade-category-element" key={`${category.id}ad`}>
            <Leader
              adId={adData.divIds.leader[mobile ? "mobile" : "desktop"][i / 2 + 1]}
              className={category.shortname}
            />
          </div>,
        );
      }
    }
    return categoryLists;
  }
  return null;
}

GradeCategories.propTypes = {
  grade: PropTypes.object.isRequired,
  games: PropTypes.array,
  categories: PropTypes.array.isRequired,
  onRefRender: PropTypes.func.isRequired,
};

GradeCategories.defaultProps = {
  games: undefined,
};
