import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useQuery } from "urql";

import { SUBSCRIPTIONS_PLAN_QUERY } from "../../graphql/queries";
import { getString, convertEPOCHDate, currencyConvert, getPlanData } from "../../utilities";
import { userRoles, nativePlatforms, billingPlatform } from "../../config";
import NoSourceContent from "./noSourceContent";
import ManageSubscriptionForm from "./manageSubscriptionForm";
import { billingPlatformSelector, planIdSelector, roleSelector } from "../../redux/slices/loginSlice";
import { useQueryHandlers } from "../../hooks";

export default function ManageSubscription({ isPremiumWeb, isUpdating, setIsUpdating, success, accountInfo }) {
  const accountBalance =
    accountInfo && accountInfo.accountBalance && accountInfo.accountBalance < 0
      ? Math.abs(accountInfo.accountBalance / 100)
      : 0;

  const loginBillingPlatform = useSelector(billingPlatformSelector);
  const role = useSelector(roleSelector);
  const planId = useSelector(planIdSelector);
  const isFree = role === userRoles.free;
  const subTitle = isFree ? getString("upgrade.title.1") : getString("manage.subscription.title.1");
  const [subscriptionsData, setSubscriptionsData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [plan, setPlan] = useState(null);
  const [originalPlan, setOriginalPlan] = useState(null);
  const [originalAmount, setOriginalAmount] = useState(null);
  const [isLegacy, setIsLegacy] = useState(false);
  const [promo, setPromo] = useState(null);
  const [queryErrors, setQueryErrors] = useState(null);

  useEffect(() => {
    if (subscriptionsData) {
      const FAMILY = getPlanData(subscriptionsData, userRoles.family, promo);
      const CLASSROOM = getPlanData(subscriptionsData, userRoles.classroom, promo);
      const SCHOOL = getPlanData(subscriptionsData, userRoles.school, promo);

      setPlanData({ FAMILY, CLASSROOM, SCHOOL });
    }
  }, [subscriptionsData, promo]);

  useEffect(() => {
    if (subscriptionsData?.subscriptions && accountInfo && !plan) {
      const existingSub = subscriptionsData.subscriptions.filter((sub) => sub.planId === planId)[0];
      const isLegacy = existingSub && !existingSub.active;

      let originalPlan = null;
      if (existingSub) {
        originalPlan = `m${existingSub.months.toString()}`;
      }

      const originalAmount = existingSub ? existingSub.amount : null;
      const plan = accountInfo.canceled || isLegacy ? null : originalPlan;

      setOriginalAmount(originalAmount);
      setPlan(plan);
      setOriginalPlan(originalPlan);
      setIsLegacy(isLegacy);
    }
  }, [subscriptionsData, accountInfo]);

  const onCompleted = (data) => {
    if (data && data.subscriptions) {
      setSubscriptionsData(data);
    }
  };

  const onError = (error) => {
    setQueryErrors(error);
  };

  const [result] = useQuery({
    query: SUBSCRIPTIONS_PLAN_QUERY,
    variables: { billingPlatform },
    requestPolicy: "network-only",
  });
  useQueryHandlers(result, onCompleted, onError);

  const renderPeriodEnd = () => {
    let periodEnd = null;
    let message = null;

    const balance =
      accountBalance > 0 ? (
        <p>
          <strong>
            {getString("manage.subscription.balance", { replace: [currencyConvert(accountBalance, "us")] })}
          </strong>
        </p>
      ) : null;

    if (isPremiumWeb && accountInfo && accountInfo.periodEnd) {
      let stringAccessor = "";
      switch (role) {
        case userRoles.school:
          stringAccessor = `subscribe.plans.school.title.2`;
          break;
        case userRoles.classroom:
          stringAccessor = `subscribe.plans.classroom.title.3`;
          break;
        default:
          stringAccessor = `subscribe.plans.${originalPlan}.title`;
      }
      const ogPlanTitle = originalPlan ? getString(stringAccessor) : null;

      const planTitle = ogPlanTitle ? `<strong>${ogPlanTitle}</strong>` : getString("subscription.0");

      const actionRenew =
        (accountInfo.sourceExists && !accountInfo.canceled) ||
        (!accountInfo.sourceExists && !accountInfo.canceled && accountBalance - originalAmount >= 0);

      const actionText = actionRenew ? getString("renew.0") : getString("expire.0");

      periodEnd = (
        <p>
          {getString("manage.subscription.renew", {
            replace: [planTitle, actionText, convertEPOCHDate(accountInfo.periodEnd)],
            html: true,
          })}
        </p>
      );
    }

    // other messaging
    // legacy users and free users
    if (isLegacy) {
      message = (
        <p>
          <strong>
            {getString("manage.subscription.messages.legacy", {
              replace: [currencyConvert(originalAmount, "us"), getString(`subscribe.plans.${originalPlan}.renewal`)],
            })}
          </strong>
        </p>
      );
    } else if (isFree) {
      message = <p>{getString("manage.subscription.messages.free")}</p>;
    }
    return (
      <div className="manage-subscription-periodend">
        {balance}
        {periodEnd}
        {message}
      </div>
    );
  };

  const renderContent = () => {
    if (nativePlatforms.includes(loginBillingPlatform)) {
      // Native Subscriber
      return <NoSourceContent planString="native" />;
    } else if (planId === "ixl_family") {
      // IXL Family Subscriber
      return <NoSourceContent planString={planId} />;
    } else if (planId === "ixl_school") {
      // IXL School Subscriber
      return <NoSourceContent planString={planId} />;
    } else if (loginBillingPlatform === "MANUAL" || planId === "friends") {
      // MANUAL Invoiced and friends and family users
      return <NoSourceContent planString="invoiced" />;
    } else if (isPremiumWeb && accountInfo && !accountInfo.sourceExists) {
      // Premium Stripe users with no credit card
      // account balance is present
      return <NoSourceContent planString="premium" />;
    }

    // Premium Stripe Users (w/credit source/balance/giftcards) && FREE/Public users
    return (
      <ManageSubscriptionForm
        accountBalance={accountBalance}
        planData={planData}
        plan={plan}
        setPlan={setPlan}
        isPremiumWeb={isPremiumWeb}
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
        success={success}
        accountInfo={accountInfo}
        setOriginalPlan={setOriginalPlan}
        setOriginalAmount={setOriginalAmount}
        promo={promo}
        setPromo={setPromo}
        queryErrors={queryErrors}
        isLegacy={isLegacy}
      />
    );
  };

  return (
    <div id="subscription" className="wrapper padded">
      <h2>{subTitle}</h2>
      {renderPeriodEnd()}
      {renderContent()}
    </div>
  );
}

ManageSubscription.propTypes = {
  isPremiumWeb: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  accountInfo: PropTypes.object,
};

ManageSubscription.defaultProps = {
  accountInfo: undefined,
};
