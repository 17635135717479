import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { assetUrl } from "../../config";
import { DEFAULT_GIFTCARD_DESIGN_QUERY } from "../../graphql/queries";
import { getString, currencyConvert, purifiedParsedString } from "../../utilities";
import ABCyaLogo from "../../../assets/svg/abcyalogo.svg";
import LoadingAnim from "../global/loadingAnim";
import WebP from "../global/WebP";
import { useLazyQuery } from "../../hooks";

export default function PreviewCard({ designSecret, message, amount, children, gcId }) {
  const [defaultDesign, setDefaultDesign] = useState(null);

  const onSuccess = (data) => {
    if (data?.giftCardDesigns.length > 0) {
      setDefaultDesign(data.giftCardDesigns[0]);
    }
  };
  const { loading, executeQuery: defaultDesignQuery } = useLazyQuery(
    DEFAULT_GIFTCARD_DESIGN_QUERY,
    onSuccess,
    (error) => console.log(error.message),
  );

  useEffect(() => {
    if (!designSecret || designSecret.length === 0) {
      defaultDesignQuery();
    }
  }, []);

  const renderMessage = () => {
    if (message?.length > 0) {
      return (
        <div className="giftcard-preview-message">
          {purifiedParsedString(message, { ALLOWED_TAGS: ["p", "br", "b", "strong", "i", "em"] })}
        </div>
      );
    }

    return null;
  };

  const renderArtwork = () => {
    if (designSecret && designSecret.length > 0) {
      // no need for default giftcard query
      return (
        <WebP
          secret={`${assetUrl}${designSecret}`}
          imgProps={{
            width: 498,
            height: 308.75,
            className: "giftcard-preview-art",
            alt: getString("giftcard.preview"),
            draggable: false,
          }}
        />
      );
    }

    if (loading) {
      return <LoadingAnim />;
    } else if (defaultDesign) {
      return (
        <WebP
          secret={`${assetUrl}${defaultDesign.file.secret}`}
          imgProps={{
            width: 498,
            height: 308.75,
            className: "giftcard-preview-art",
            alt: getString("giftcard.preview"),
            draggable: false,
          }}
        />
      );
    }
    return null;
  };

  const exampleId = gcId || getString("giftcard.claim.example");

  return (
    <div className="giftcard-preview">
      {renderArtwork()}

      {renderMessage()}

      <div className="giftcard-preview-logo">
        <div className="giftcard-preview-amount">
          <div className="giftcard-preview-amount-cost">{currencyConvert(amount, "us")}</div>
          <div className="giftcard-preview-amount-subtitle">{getString("giftcard.subtitle")}</div>
        </div>

        <ABCyaLogo className="giftcard-preview-logo-img" />
      </div>

      <div className="giftcard-preview-code">{`${getString("giftcard.claim.title")} ${exampleId}`}</div>

      {children}
    </div>
  );
}

PreviewCard.propTypes = {
  designSecret: PropTypes.string,
  message: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any,
  gcId: PropTypes.string,
};

PreviewCard.defaultProps = {
  children: null,
  gcId: undefined,
  designSecret: null,
};
