import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormProvider, useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useMutation, useQuery } from "urql";

import { billingPlatform, recaptchaSiteKey, stripePublishableKeys, userRoles } from "../../config";
import { metaDefault } from "../../config/meta";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getPlanData, getString, handlePostRequest } from "../../utilities";
import AccountFields from "./accountFields";
import PaymentFields from "./paymentFields";
import StudentFields from "../global/studentFields";
import SchoolPlans from "./schoolPlans";
import { submitError, subscribeSubmit } from "../../utilities/subscribe";
import NotAppliedModal from "../global/notAppliedModal";
import SubscribeConfirm from "../global/subscribeConfirm";
import { RECAPTCHA_VERIFY_QUERY, SUBSCRIPTIONS_PLAN_QUERY } from "../../graphql/queries";
import { gtmSubscribed } from "../../utilities/gtm";
import SubscribedModal from "./subscribedModal";
import { useLazyQuery, useQueryHandlers } from "../../hooks";
import { getStripeLocale } from "../../utilities/stripe";

const plan = "m12";

function Form() {
  const [total, setTotal] = useState(0);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNotApplied, setGiftCardNotApplied] = useState(false);
  const [notAppliedModal, setNotAppliedModal] = useState({ show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState("");
  const [planData, setPlanData] = useState(null);
  const [planId, setPlanId] = useState("");
  const [amount, setAmount] = useState(null);
  const [name, setName] = useState("");

  const methods = useForm({ mode: "onChange" });
  const { getValues, handleSubmit, formState } = methods;
  const { accessName, accessCode } = getValues();

  const location = useLocation();

  const stripe = useStripe();
  const elements = useElements();

  const onSubCompleted = (data) => {
    const nextPlanData = getPlanData(data, userRoles.school);
    setPlanData(nextPlanData);
    setPlanId(nextPlanData[plan].planId);
    setAmount(nextPlanData[plan].amount);
    setName(nextPlanData[plan].name);
  };

  const [subResult] = useQuery({
    query: SUBSCRIPTIONS_PLAN_QUERY,
    variables: { billingPlatform },
  });
  const { fetching: subsLoading, error } = subResult;
  useQueryHandlers(subResult, onSubCompleted, () => null);

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify, accessName, accessCode } = getValues();

    const myVariables = {
      email: email.trim(),
      emailVerify: emailVerify.trim(),
      password,
      passwordVerify,
      billingPlan: planData[plan].planId,
      accessName,
      accessCode,
      giftCards,
    };

    return myVariables;
  };

  const creditNotRequired = amount < total;
  const action = "School_Form_Submission";

  const onSignupCompleted = (data) => {
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      cardElement.clear();
    }

    const { signup } = data;
    gtmSubscribed({ planId, amount, name });
    gtmSubscribed({ planId, amount, name }, { invoiceId: data?.signup?.invoiceId });

    setSubmitting(false);
    setToken(signup.token);

    setSubmitErrors(null);
  };

  const onSignupError = (error) => {
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [signupResult, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: loading } = signupResult;

  const onRecaptchaCompleted = async (data) => {
    const myVariables = getVariables();
    const cardElement = elements.getElement(CardElement);
    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement,
      mutation: (variables) =>
        signup(variables).then((result) => handlePostRequest(result, onSignupCompleted, onSignupError)),
      creditNotRequired,
      creditComplete,
      action,
      stripe,
      myVariables,
      plan: { planId, amount, name },
      setSubmitting,
    });
  };

  const onRecaptchaError = (error) => {
    console.log("recaptcha error: ", error);
    submitError(error, setSubmitErrors, null);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  const renderErrors = (error) => {
    if (error) {
      return (
        <div className="wrapper padded">
          <div className="container-medium">
            <ErrorMessage error={error} />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderForm = () => {
    return (
      <div className="subscribe">
        <FormProvider {...methods}>
          {metaDefault({
            path: location.pathname,
            title: `${getString("join.3")} • ${getString("subscribe.plans.school.title.1")}`,
            noCrawl: true,
          })}
          <div className="wrapper padded">
            <h1>{getString("subscribe.plans.school.cta")}</h1>
          </div>

          <SchoolPlans plan={plan} planData={planData} />

          <form
            onSubmit={handleSubmit(() => {
              if (!submitting) {
                if (giftCardNotApplied) {
                  // there is a giftcard field filled out but not applied
                  // modal call to action to apply giftcard
                  setNotAppliedModal({ show: true, type: "giftCard" });
                } else {
                  // subscribe confirmation modal
                  setConfirmModal((prev) => !prev);
                }
              }
            })}
          >
            <div className="wrapper padded">
              <div className="container-small">
                <OptionallyDisplayed doDisplay={!!submitErrors}>
                  <ErrorMessage error={submitErrors} />
                </OptionallyDisplayed>

                <h2>{getString("subscribe.create")}</h2>
                <div className="header-caption">{getString("subscribe.school.check", { html: true })}</div>

                <AccountFields
                  errors={formState.errors}
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                />
                <StudentFields submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />
                <PaymentFields
                  total={total}
                  setTotal={setTotal}
                  setGiftCardNotApplied={setGiftCardNotApplied}
                  giftCards={giftCards}
                  setGiftCards={setGiftCards}
                  errors={formState.errors}
                  doesNotRequireCredit={creditNotRequired}
                  setCreditComplete={setCreditComplete}
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                  planGroup="school"
                  isNewSubscriber
                />

                <button type="submit" className="button-flat-color pt-green" disabled={submitting}>
                  {getString("subscribe.submit")}
                </button>

                <div className="subscribe-terms">
                  <p>{getString("subscribe.terms", { replace: [getString("subscribe.title.0")], html: true })}</p>
                  <p>{getString("recaptcha", { html: true })}</p>
                </div>
              </div>
            </div>
          </form>

          <SubscribeConfirm
            doShow={confirmModal}
            submit={() => {
              setConfirmModal((prev) => !prev);
              window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                  recaptchaVerify({ token, action, verify: true });
                });
              });
            }}
            cancel={() => {
              setConfirmModal((prev) => !prev);
            }}
            planData={{
              title: getString("subscribe.plans.school.title.1"),
              plan,
              amount,
              finalAmount: amount,
              creditNotRequired,
              giftCards: { cards: giftCards, total },
            }}
          />

          <NotAppliedModal
            doShow={notAppliedModal.show}
            type={notAppliedModal.type || "giftCard"}
            close={() => {
              setNotAppliedModal({ show: false });
            }}
          />

          {(loading || recaptchaLoading || submitting) && (
            <LoadingAnim position="fixed" className="background-transparent-white">
              <h4>{getString("subscribe.loading")}</h4>
            </LoadingAnim>
          )}
        </FormProvider>
      </div>
    );
  };

  return (
    <>
      <OptionallyDisplayed doDisplay={subsLoading}>
        <div className="wrapper padded">
          <LoadingAnim />
        </div>
      </OptionallyDisplayed>
      <OptionallyDisplayed doDisplay={!subsLoading}>
        {renderErrors(error)}
        <OptionallyDisplayed doDisplay={!!planData && !error}>{renderForm()}</OptionallyDisplayed>
      </OptionallyDisplayed>
      <SubscribedModal token={token} accessName={accessName} accessCode={accessCode} />
    </>
  );
}

export default function StripeSchoolForm(props) {
  const stripePromise = useMemo(() => loadStripe(stripePublishableKeys[process.env.NODE_ENV]), []);
  return (
    <Elements stripe={stripePromise} options={{ locale: getStripeLocale() }}>
      <Form {...props} />
    </Elements>
  );
}
