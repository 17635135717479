import React from "react";
import PropTypes from "prop-types";
import Modal from "./modal";
import Confirm from "./confirm";
import { currencyConvert, getString } from "../../utilities";

export default function SubscribeConfirm({ doShow, submit, cancel, planData }) {
  const { title, plan, finalAmount, promo, creditNotRequired, giftCards } = planData;

  let total = finalAmount;
  if (giftCards && giftCards.total) {
    total -= giftCards.total;
    if (total < 0) total = 0;
  }

  const costDisclaimer = () => {
    // creditNotRequired
    if (creditNotRequired) {
      return null;
    }

    let disclaimerText = "";

    if (plan) {
      disclaimerText = getString(`subscribe.plans.${planData.plan}.disclaimer.${plan.endsWith("1") ? 0 : 1}`);
    }

    return <div className="pc-lineitem-disclaimer">{disclaimerText}</div>;
  };

  const renderPromo = () => {
    if (promo) {
      let offText = getString("promo.off", { replace: [`${promo.percentOff * 100}%`] });
      if (promo.amountOff > 0) {
        offText = getString("promo.off", { replace: [currencyConvert(promo.amountOff)] });
      }
      return (
        <>
          <div className="pc-lineitem">
            <span className="pc-lineitem-title">{getString("promo.applied")}: </span>
            <strong>{offText}</strong>
            {!creditNotRequired && <div className="pc-lineitem-disclaimer">{promo.disclaimer}</div>}
          </div>
          <div className="pc-lineitem">
            {/* cost minus discount (finalAmount) */}
            <span className="pc-lineitem-title">{getString("subtotal.0")}: </span>
            <strong>{currencyConvert(finalAmount)}</strong>
          </div>
        </>
      );
    }
    return null;
  };

  const renderGiftCards = () => {
    if (giftCards && giftCards.total > 0) {
      return (
        <div className="pc-lineitem">
          <span className="pc-lineitem-title">{getString("giftcard.total.1")}</span>{" "}
          <strong>{currencyConvert(giftCards.total)}</strong>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal doShow={doShow}>
      <Confirm
        confirm={async () => {
          submit();
        }}
        cancel={async () => {
          cancel();
        }}
        button={getString("subscribe.submit")}
      >
        <div className="modal-content-header">{getString("subscribe.confirm.title")}</div>
        <div className="purchase-confirm">
          <p>
            {getString("subscribe.confirm.message")}
            <br />
            <strong>{`${title}, ${plan && getString(`subscribe.plans.${plan}.title`)}`}</strong>
          </p>
          <div className="pc-lineitems">
            <div className="pc-lineitem">
              <span className="pc-lineitem-title">{getString("cost.0")}: </span>
              <strong>{currencyConvert(planData.amount)}</strong>
              {costDisclaimer()}
            </div>
            {renderPromo()}
            {renderGiftCards()}
            <div className="pc-total">
              {/* subtotal - giftcard credits  */}
              <span className="pc-total-title">{getString("total.0")}: </span>
              {currencyConvert(total)}
            </div>
          </div>
        </div>
      </Confirm>
    </Modal>
  );
}

SubscribeConfirm.propTypes = {
  doShow: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  planData: PropTypes.object.isRequired,
};
