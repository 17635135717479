import React from "react";
import PropTypes from "prop-types";
import Modal from "./modal";
import Confirm from "./confirm";
import { getString } from "../../utilities";

export default function LeavingSiteModal({ doShow, cancelFunction, url }) {
  return (
    <Modal doShow={doShow} closeOnEscape={cancelFunction}>
      <Confirm
        confirm={() => {
          // close the door(modal) behind you
          cancelFunction();

          window.open(url, "_blank", "noopener,noreferrer");
        }}
        cancel={cancelFunction}
        button={getString("continue.0")}
        cancelButton={getString("cancel.4")}
      >
        <div className="modal-content-header">{getString("attention.0")}</div>
        <div className="leaving-modal">
          <p>{getString("leaving.message")}</p>
          <p>{getString("leaving.cta")}</p>

          <p className="leaving-modal-disclaimer">{getString("leaving.disclaimer")}</p>
        </div>
      </Confirm>
    </Modal>
  );
}

LeavingSiteModal.propTypes = {
  doShow: PropTypes.bool.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  url: PropTypes.string,
};

LeavingSiteModal.defaultProps = {
  url: undefined,
};
