import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { contentTypes, getLangCode, language } from "../../config";
import List from "../global/list";
import { getString } from "../../utilities";
import categoryMbArt, { categoryUrlName } from "../../utilities/categoryMbArt";
import { printableCategoryNavNames } from "../../config/categories";

const genderAgreement = (string, catName) => {
  const isFeminine = !!catName.split("-")[0].match(/as$/i);
  return isFeminine ? string.replace(/os$/, "as") : string;
};

export default function SubCategoryNav({ categories, route }) {
  const { shortname: snParam } = useParams();
  const { cat: catParam } = useParams();

  const baseStyle = "mb-label-center";
  const activeStyle = `${baseStyle} active`;

  const allImage = categoryMbArt(contentTypes.printable);

  const items = [];

  let isOneActive = false;

  categories.forEach((cat) => {
    const { image, fallback } = categoryMbArt(contentTypes.printable, cat.shortname);

    const catPath = cat.localeShortname ? cat.localeShortname : cat.shortname;
    const catItem = {
      type: contentTypes.link,
      style: !!catParam && `${snParam}-${catParam}` === catPath ? activeStyle : baseStyle,
      title: cat.name,
      clickHandler: `${route}/${categoryUrlName(cat.localeShortname || cat.shortname, {
        catTypes: printableCategoryNavNames,
      })}`,
      image,
      fallbackImage: fallback,
    };

    if (!!catParam && `${snParam}-${catParam}` === (cat.localeShortname ? cat.localeShortname : cat.shortname)) {
      catItem.style = activeStyle;
      isOneActive = true;
    } else {
      catItem.style = baseStyle;
    }

    items.push(catItem);
  });

  items.unshift({
    type: contentTypes.link,
    style: !catParam || !isOneActive ? activeStyle : baseStyle,
    title: getLangCode() === language.la ? genderAgreement(getString("all.1"), snParam) : getString("all.1"),
    clickHandler: route,
    image: allImage.image,
    fallbackImage: allImage.fallback,
  });

  return <List hasNoScrollReset={true} items={items} className="categoryprintables-list" />;
}

SubCategoryNav.propTypes = {
  categories: PropTypes.array,
  route: PropTypes.string.isRequired,
};

SubCategoryNav.defaultProps = {
  categories: undefined,
};
