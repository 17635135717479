import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import aboutStrings from "../../strings/about";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { getLangCode, language } from "../../config";

export default function TeamMember({ favorite, artwork, name, job }) {
  const dataLoading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);

  const [loading, setLoading] = useState(true);
  const [favGame, setFavGame] = useState(null);
  const [gameRoute, setGameRoute] = useState(null);

  useEffect(() => {
    if (!dataLoading && data.games) {
      const game = data.games.find((g) => g.shortname === favorite) || null;
      setFavGame(game?.fullname || null);
      setGameRoute(favorite);
      setLoading(dataLoading);
    }
  }, [dataLoading, data]);

  const renderFavorite = () => {
    if (getLangCode() === language.default && favGame && favGame.length > 0) {
      return (
        <div className="team-member-text-favorite">
          <div>{getString("team.favorite", { strings: aboutStrings })}</div>
          <Link className="team-member-text-game" to={`${routeCodes.GAMES}${gameRoute}`}>
            {favGame}
          </Link>
        </div>
      );
    }

    return null;
  };

  if (loading) {
    return null;
  }

  return (
    <div className="team-member-single">
      <img className="team-member-artwork" src={artwork} alt={name} width="100%" height="auto" />
      <div className="team-member-text-container">
        <div className="team-member-text-name">{name}</div>
        <div className="team-member-text-job">
          <div>{getString(job, { strings: aboutStrings })}</div>
        </div>
        {renderFavorite()}
      </div>
    </div>
  );
}

TeamMember.propTypes = {
  favorite: PropTypes.string.isRequired,
  search: PropTypes.object,
  artwork: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
};

TeamMember.defaultProps = {
  search: undefined,
};
