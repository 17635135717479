import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

export default function TextViewHook({
  name,
  type,
  defaultValue,
  errors,
  placeholder,
  label,
  className,
  isReadOnly,
  children,
  onChange,
  rules,
  dataTestId,
}) {
  const [showLabel, setShowLabel] = useState(false);
  const { register } = useFormContext();

  const formFieldClass = `form-field ${className}`;

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setShowLabel(true);
    }
  }, []);

  const handleInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    setShowLabel(e.target.value.length > 0);
  };

  const renderLabel = () => {
    if (label && label.length > 0) {
      const labelClass = showLabel ? "active" : "";

      return (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      );
    }

    return null;
  };

  const renderTag = () => {
    const hold = placeholder || label || "";

    if (type === "textarea") {
      return (
        <textarea
          id={name}
          placeholder={hold}
          defaultValue={defaultValue || ""}
          {...register(name, { ...rules, onChange: handleInputChange })}
          readOnly={isReadOnly}
          data-testid={dataTestId}
        />
      );
    }

    return (
      <input
        id={name}
        type={type || "text"}
        placeholder={hold}
        defaultValue={defaultValue || ""}
        {...register(name, { ...rules, onChange: handleInputChange })}
        readOnly={isReadOnly}
        data-testid={dataTestId}
      />
    );
  };

  return (
    <div className={formFieldClass}>
      {children}
      <div className="form-field-container">
        {renderLabel()}
        {renderTag()}
        {errors[name] && <div className="validation-error">{errors[name].message}</div>}
      </div>
    </div>
  );
}

TextViewHook.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.object,
  dataTestId: PropTypes.string,
};

TextViewHook.defaultProps = {
  children: null,
  className: "",
  defaultValue: undefined,
  label: undefined,
  placeholder: undefined,
  isReadOnly: false,
  type: "text",
  onChange: undefined,
  rules: undefined,
  dataTestId: undefined,
};
