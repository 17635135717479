import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginSlice";
import userClientReducer from "./slices/userClientSlice";
import dataReducer from "./slices/dataSlice";
import timerReducer from "./slices/timerSlice";
import leavingSiteReducer from "./slices/leavingSiteSlice";
import fullPageReducer from "./slices/fullPageSlice";
import printSaveReducer from "./slices/printSaveSlice";
import desktopSignupReducer from "./slices/desktopSignupSlice";
import preContentVideoReducer from "./slices/preContentVideoSlice";

const reducers = combineReducers({
  login: loginReducer,
  userClient: userClientReducer,
  data: dataReducer,
  timer: timerReducer,
  leavingSite: leavingSiteReducer,
  fullPage: fullPageReducer,
  printSave: printSaveReducer,
  desktopSignup: desktopSignupReducer,
  preContentVideo: preContentVideoReducer,
});

const initializeStore = () => {
  return configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== "production",
  });
};

let store = initializeStore();

export const resetStore = () => {
  store = initializeStore();
};

export default () => store;
