import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { addNoScroll, removeNoScroll } from "../../utilities";

export default function BodyNoScroll({ isActive, children }) {
  const location = useLocation();

  const isMounted = useRef(false);

  useEffect(() => {
    if (isActive) {
      addNoScroll();
    }
    return removeNoScroll;
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      removeNoScroll();
    } else {
      isMounted.current = true;
    }
  }, [location]);

  useEffect(() => {
    if (isActive) {
      addNoScroll();
    } else {
      removeNoScroll();
    }
  }, [isActive]);

  return <>{children}</>;
}

BodyNoScroll.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

BodyNoScroll.defaultProps = {
  children: null,
};
