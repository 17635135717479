import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useCombobox } from "downshift";
import PropTypes from "prop-types";

import OptionallyDisplayed from "./optionallyDisplayed";
import MagnifySearchField from "../../../assets/svg/magnifySearchField.svg";
import X from "../../../assets/svg/x.svg";

export default function SearchBox({
  searched,
  setSearched,
  setSearching,
  search,
  onSuggestionsFetchRequested,
  suggestions,
  setSuggestions,
  showSearch,
  placeholder,
  catStyle,
  initialInputValue,
}) {
  const { pathname } = useLocation();
  const prevPath = useRef();

  const handleSearchSubmit = async (e, value) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (setSearching) {
      setSearching(true);
    }
    const found = await search(value);
    setSearched(found);
    if (setSearching) {
      setSearching(false);
    }
  };

  const { inputValue, isOpen, closeMenu, getMenuProps, getInputProps, highlightedIndex, getItemProps, setInputValue } =
    useCombobox({
      items: suggestions,
      onInputValueChange: ({ inputValue }) => {
        setInputValue(inputValue);
        onSuggestionsFetchRequested({ value: inputValue });
        if (inputValue.trim() === "") {
          setSearched(null);
        }
      },
      onStateChange: (changes) => {
        switch (changes.type) {
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            const input = changes.selectedItem || inputValue;
            handleSearchSubmit(null, input);
            break;
          default:
        }
      },
      initialInputValue,
    });

  const handleSearchClear = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setInputValue("");

    if (searched?.length > 0) {
      setSearched(null);
      if (setSearching) {
        setSearching(false);
      }
      setSuggestions([]);
    }
  };

  const submitFormHandler = (e) => {
    closeMenu();
    handleSearchSubmit(e, inputValue);
  };

  useEffect(() => {
    if (prevPath.current !== pathname) {
      if (prevPath.current) {
        handleSearchClear();
      }
      prevPath.current = pathname;
    }
  }, [pathname]);

  return (
    <form onSubmit={submitFormHandler} className={`content-search-form${searched ? " searched" : ""} ${catStyle}`}>
      <div>
        <div>
          <input placeholder={placeholder} {...getInputProps()} />
        </div>
        <ul {...getMenuProps()} className="search-suggestions-container suggestions-list">
          {isOpen &&
            suggestions.map((item, index) => {
              return (
                <li
                  className={`suggestion${highlightedIndex === index ? " highlighted" : ""}`}
                  key={`${item}`}
                  {...getItemProps({ item, index })}
                >
                  {item}
                </li>
              );
            })}
        </ul>
      </div>

      <OptionallyDisplayed doDisplay={!!searched}>
        <button type="button" className="search-clear" onClick={handleSearchClear} data-testid="search-clear-button">
          <X />
        </button>
      </OptionallyDisplayed>

      <OptionallyDisplayed doDisplay={showSearch}>
        <button type="submit" className="search-submit" data-testid="search-submit">
          <MagnifySearchField />
        </button>
      </OptionallyDisplayed>
    </form>
  );
}

SearchBox.propTypes = {
  // Search results.
  searched: PropTypes.array,
  // Setter for searched.
  setSearched: PropTypes.func.isRequired,
  // Whether search is currently in progress.
  setSearching: PropTypes.func,
  // Function that actually searches.
  search: PropTypes.func.isRequired,
  // Gets search suggestions.
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  // Search suggestions.
  suggestions: PropTypes.array.isRequired,
  // Setter for suggestions.
  setSuggestions: PropTypes.func.isRequired,
  // Whether magnifying glass search button should be displayed or not.
  showSearch: PropTypes.bool,
  // Placeholder for search text field.
  placeholder: PropTypes.string,
  // Style for printable categories.
  catStyle: PropTypes.string,
  // Input value used for prepopulated search
  initialInputValue: PropTypes.string,
};

SearchBox.defaultProps = {
  placeholder: "",
  searched: null,
  catStyle: "",
  setSearching: undefined,
  showSearch: null,
  initialInputValue: "",
};
