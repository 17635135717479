import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { dataSelector } from "../../redux/slices/dataSlice";
import Standards from "../global/Standards";

export default function GameStandards({ game }) {
  const { standards: dataStandards } = useSelector(dataSelector);

  const standards = game?.standards
    ? game.standards.reduce((acc, standardId) => {
        const nextAcc = acc;
        const standardData = dataStandards.find((standard) => standard.id === standardId);
        if (standardData) {
          nextAcc.push(standardData);
        }
        return nextAcc;
      }, [])
    : [];

  if (standards) {
    return <Standards standards={standards} />;
  }
  return null;
}

GameStandards.propTypes = {
  game: PropTypes.object,
};

GameStandards.defaultProps = {
  game: undefined,
};
