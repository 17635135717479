import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import TextViewHook from "../global/textViewHook";
import { getMaxLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { EMAIL_CHAR_LIMIT, TEXT_FIELD_CHAR_LIMIT } from "../../config";
import { useFormContext } from "react-hook-form";

export default function Giftcards({ fields, submitErrors, setSubmitErrors }) {
  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="wrapper padded">
            <div className="container giftcard-details">
              <div className="giftcard-details-title">{`${getString("giftcard.title.1")} ${index + 1}`}</div>

              <div className="static-label">{getString("giftcard.recipientEmail")}</div>
              <TextViewHook
                name={`recipientEmail${index}`}
                placeholder={getString("giftcard.optional")}
                rules={{
                  validate: (value) =>
                    value.length === 0 ||
                    validateEmail(value, getString("giftcard.emailError", { replace: [index + 1] })),
                  maxLength: getMaxLengthValidator(
                    getString("giftcard.emailError", { replace: [index + 1] }),
                    EMAIL_CHAR_LIMIT,
                  ),
                }}
                errors={errors}
                onChange={onChange}
              />

              <div className="static-label">{getString("giftcard.message")}</div>
              <TextViewHook
                name={`message${index}`}
                type="textarea"
                placeholder={getString("giftcard.optional")}
                rules={{
                  maxLength: getMaxLengthValidator(
                    getString("giftcard.messageError", { replace: [index + 1] }),
                    TEXT_FIELD_CHAR_LIMIT,
                  ),
                }}
                errors={errors}
                onChange={onChange}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

Giftcards.propTypes = {
  fields: PropTypes.array.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,
};

Giftcards.defaultProps = {
  submitErrors: null,
};
