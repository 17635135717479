import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import SubscriptionCard from "../subscribe/subscriptionCard";

export default function ClassroomPlans({ plan, setPlan, setPlanAmount, planData }) {
  return (
    <div className="wrapper plans-wrapper classroom">
      <div className="plans-title">{getString("subscribe.plans.classroom.title.1")}</div>
      <div className="plans-subs">
        <SubscriptionCard
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData}
          subscription="m12"
        />
      </div>
    </div>
  );
}

ClassroomPlans.propTypes = {
  plan: PropTypes.string,
  setPlan: PropTypes.func.isRequired,
  setPlanAmount: PropTypes.func.isRequired,
  planData: PropTypes.object.isRequired,
};

ClassroomPlans.defaultProps = {
  plan: undefined,
};
