import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { mobile } from "../../utilities/detection";
import { metaDefault } from "../../config/meta";
import MobileFree from "./mobileFree";
import HomeNav from "./homeNav";
import HomeLists from "./homeLists.jsx";
import { routeCodes } from "../../config/routes";
import { isLoggedInSelector, modalToggle } from "../../redux/slices/loginSlice";
import { logout } from "../../utilities";
import { getParamValue } from "../../config/index.js";

export default function Home({ shouldLogin }) {
  const location = useLocation();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  const handleLogin = () => {
    const accessName = getParamValue("name", { location });
    const accessCode = getParamValue("code", { location });
    const redirectPath = getParamValue("path", { location }) || routeCodes.HOME;

    if (!isLoggedIn) {
      dispatch(modalToggle({ path: redirectPath }));
    } else if (accessName || accessCode) {
      logout();
    } else {
      window.location.assign(redirectPath);
    }
  };

  useEffect(() => {
    if (shouldLogin) {
      handleLogin();
    }
  }, []);

  const mobileStyle = mobile ? " mobile" : "";

  return (
    <>
      {metaDefault({ path: location.pathname })}

      <div className={`home${mobileStyle}`}>
        <MobileFree />

        <HomeNav />

        <HomeLists />
      </div>
    </>
  );
}

Home.propTypes = {
  shouldLogin: PropTypes.bool,
};

Home.defaultProps = {
  shouldLogin: false,
};
