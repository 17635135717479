import React from "react";
import PropTypes from "prop-types";

export default function LoadingAnim({ position, className, children }) {
  const getClassName = () => {
    const base = "main-loading-wrapper";
    let myClass = position ? `${base} ${position}` : base;
    myClass = className ? `${myClass} ${className}` : myClass;

    return myClass;
  };

  return (
    <div data-testid="loading-animation" className={getClassName()}>
      {children || null}
      <div className="loading-orbit-anim">
        <div className="orbit-satelite" />
        <div className="orbit-body" />
      </div>
    </div>
  );
}

LoadingAnim.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

LoadingAnim.defaultProps = {
  position: undefined,
  className: undefined,
  children: null,
};
