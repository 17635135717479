import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";

export default function Confirm({ children, confirm, button, cancel, cancelButton }) {
  return (
    <div className="modal-content-confirm">
      {children}
      <div className="modal-content-confirm-buttons">
        <button
          className="button-flat-color pt-green"
          type="button"
          onClick={confirm}
          data-testid={`${button}-confirm-button`}
        >
          {button}
        </button>
        <button className="button-flat-color pt-orange" type="button" onClick={cancel}>
          {cancelButton}
        </button>
      </div>
    </div>
  );
}

Confirm.propTypes = {
  children: PropTypes.any.isRequired,
  confirm: PropTypes.func.isRequired,
  button: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  cancelButton: PropTypes.string,
};

Confirm.defaultProps = {
  button: getString("ok"),
  cancelButton: getString("cancel.1"),
};
