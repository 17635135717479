import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { CHECK_GIFTCARD_QUERY } from "../../graphql/queries";
import TextViewHook from "./textViewHook";
import LoadingAnim from "./loadingAnim";
import ErrorMessage from "./errorMessage";
import { getString } from "../../utilities";
import { giftcardMax } from "../../config";
import OptionallyDisplayed from "./optionallyDisplayed";
import { validateGiftCard } from "../../utilities/forms/rules";
import { useLazyQuery } from "../../hooks";

export default function GiftCardField({
  setGiftCardNotApplied,
  total,
  setTotal,
  giftCards,
  setGiftCards,
  setNumRedeemed,
  index,
}) {
  const [amount, setAmount] = useState(0);
  const [redeemedCode, setRedeemedCode] = useState(null);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [giftCardInvalid, setGiftCardInvalid] = useState(true);

  const {
    trigger,
    formState: { errors },
  } = useFormContext();
  const giftCardName = `giftCard${index}`;

  const isValidGiftCard = (value) => value.length === 0 || validateGiftCard(value);

  const applyGiftCard = (data) => {
    if (data && data.checkGiftCard) {
      const { checkGiftCard } = data;

      if (checkGiftCard.redeemed) {
        // already redeemed
        // supply error message {message: "my message"}
        setSubmitErrors({ message: getString("giftcard.errors.redeemed") });
      } else {
        // not redeemed - perform duties
        // add up total
        const newTotal = total + checkGiftCard.amount;

        if (newTotal <= giftcardMax) {
          // if less than or equal to 500 add to total in grandparent
          // increase number of redeemed cards in parent
          setNumRedeemed((prevNumRedeemed) => prevNumRedeemed + 1);

          // mark this component as redeemed by setting amount state
          // amount > 0 = redeemed
          setAmount(checkGiftCard.amount);
          // store card id for setting value in readonly field
          setRedeemedCode(checkGiftCard.id);

          // add redeemed giftcard code/id to grandparent giftcards array
          // what will be submitted in final mutation
          setTotal(newTotal);
          setGiftCards((prevGiftCards) => {
            return [...prevGiftCards, checkGiftCard.id];
          });
          setGiftCardNotApplied(false);
        } else {
          // exceeds giftcard max redemption
          // supply error message
          setSubmitErrors({ message: getString("giftcard.errors.max", { replace: [giftcardMax] }) });
        }
      }
    } else {
      // came back null (no such giftcard in database)
      // supply error message
      setSubmitErrors({ message: getString("giftcard.errors.invalid") });
    }
  };

  const renderButtonText = (redeemed) => {
    return redeemed ? (
      <>
        {getString("currencySymbol.usd")}
        {amount}
        <br />
        {getString("applied")}
      </>
    ) : (
      <>{getString("apply")}</>
    );
  };

  const handleGiftCardChanged = (e) => {
    const event = e;
    event.target.value = event.target.value.trim();
    if (amount === 0) {
      // only if it's a field that hasn't been applied
      // otherwise amount will be greater than 0
      setGiftCardNotApplied(e.target.value.length > 0);
    }
    const isValid = isValidGiftCard(e.target.value);
    trigger(giftCardName);
    setGiftCardInvalid(!isValid || e.target.value.length === 0);
    setRedeemedCode(e.target.value);
    setSubmitErrors(null);
  };

  const { executeQuery: checkGiftCardQuery, loading } = useLazyQuery(
    CHECK_GIFTCARD_QUERY,
    (data) => applyGiftCard(data),
    (error) => setSubmitErrors(error),
  );

  if (loading) {
    return (
      <LoadingAnim>
        <p>{getString("giftcard.applying")}</p>
      </LoadingAnim>
    );
  }

  const redeemed = amount > 0;

  return (
    <>
      <OptionallyDisplayed doDisplay={!!submitErrors}>
        <ErrorMessage error={submitErrors} />
      </OptionallyDisplayed>

      <div className={`giftcard-field ${redeemed ? "redeemed" : ""}`}>
        <TextViewHook
          rules={{
            validate: (value) => isValidGiftCard(value) || getString("giftcard.errors.invalid"),
          }}
          name={giftCardName}
          isReadOnly={redeemed}
          errors={errors}
          placeholder={redeemed ? redeemedCode : getString("redeem.code.0")}
          label={getString("redeem.code.0")}
          onChange={handleGiftCardChanged}
          defaultValue={redeemedCode}
        />
        <button
          type="button"
          disabled={giftCardInvalid || redeemed}
          className={`button-flat-color pt-steel apply-button ${redeemed ? "redeemed" : ""}`}
          onClick={() => {
            if (!loading) {
              if (giftCards.includes(redeemedCode.toLowerCase())) {
                // giftcard code already applied supply error

                setSubmitErrors({ message: getString("giftcard.errors.applied") });
              } else if (!giftCardInvalid) {
                // only call query if the giftcard has a valid (properly formatted) code
                // and has not been applied already (grandparent giftCards array)
                setSubmitErrors(null);

                checkGiftCardQuery({ id: redeemedCode });
              }
            }
          }}
          data-testid="giftcard-apply-button"
        >
          {renderButtonText(redeemed)}
        </button>
      </div>
    </>
  );
}

GiftCardField.propTypes = {
  setGiftCardNotApplied: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  setTotal: PropTypes.func.isRequired,
  giftCards: PropTypes.array.isRequired,
  setGiftCards: PropTypes.func.isRequired,
  setNumRedeemed: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
