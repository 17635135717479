import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { getString, removeTrailingSlash } from "../../utilities";
import TextViewHook from "./textViewHook";
import { getMaxLengthValidator, validateAlphaNumeric } from "../../utilities/forms/rules";
import { ACCESS_NAME_CHAR_LIMIT, PASSWORD_CHAR_LIMIT, getSearchParamString } from "../../config";
import routeCodes from "../../config/routeCodes";

export default function StudentFields({ studentAccess, submitErrors, setSubmitErrors }) {
  const {
    formState: { errors },
  } = useFormContext();

  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  const renderChildren = (item) => {
    if (studentAccess) {
      return (
        <div className="form-field-children">
          <div className="label">{getString("manage.accountInfo.current")}</div>
          <div className="current-value">{studentAccess[item]}</div>
        </div>
      );
    }

    return null;
  };

  const renderAccessUrl = () => {
    if (studentAccess) {
      return (
        <div className="manage-account-url">
          <div className="label">{getString("manage.accountInfo.student.autoURL")}</div>
          <div className="current-value">{`${window.location.origin}${removeTrailingSlash(
            routeCodes.LOGIN,
          )}?${getSearchParamString("name")}=${studentAccess.accessName}&${getSearchParamString("code")}=${
            studentAccess.accessCode
          }`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="cta">{getString("subscribe.student")}</div>
      <div className="cta-caption">{getString("subscribe.accessDesc")}</div>

      <TextViewHook
        rules={{
          validate: {
            lowAndNo: (value) => validateAlphaNumeric(getString("forms.accessName"), value),
            required: (value) =>
              !!studentAccess ||
              !!value ||
              getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.accessName")] }),
          },
          maxLength: getMaxLengthValidator(getString("forms.accessName"), ACCESS_NAME_CHAR_LIMIT),
        }}
        errors={errors}
        className="col2"
        name="accessName"
        placeholder={getString("forms.accessName")}
        label={getString("forms.accessName")}
        onChange={onChange}
      >
        {renderChildren("accessName")}
      </TextViewHook>

      <TextViewHook
        rules={{
          validate: (value) =>
            !!studentAccess ||
            !!value ||
            getString("forms.errorMessages.isRequired.0", { replace: [getString("forms.accessCode")] }),
          maxLength: getMaxLengthValidator(getString("forms.accessCode"), PASSWORD_CHAR_LIMIT),
        }}
        errors={errors}
        className="col2 right"
        name="accessCode"
        placeholder={getString("forms.accessCode")}
        label={getString("forms.accessCode")}
        onChange={onChange}
      >
        {renderChildren("accessCode")}
      </TextViewHook>

      {renderAccessUrl()}
    </>
  );
}

StudentFields.propTypes = {
  studentAccess: PropTypes.object,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func,
};

StudentFields.defaultProps = {
  studentAccess: undefined,
  submitErrors: undefined,
  setSubmitErrors: undefined,
};
