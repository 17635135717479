import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { adData } from "../config";
import { mobile } from "../utilities/detection";

export const AdContext = React.createContext();

export default function AdProvider({ children }) {
  const leaderDivs = useRef({});
  leaderDivs.current = (mobile ? adData.divIds.leader.mobile : adData.divIds.leader.desktop).reduce((leaders, id) => {
    const divElement = document.createElement("div");
    divElement.id = id;
    return { ...leaders, [id]: { div: divElement, created: false } };
  }, {});

  const skyLeftDiv = useRef(mobile ? null : document.createElement("div"));
  const skyRightDiv = useRef(mobile ? null : document.createElement("div"));
  const billboardDiv = useRef(mobile ? null : document.createElement("div"));

  const skyLeftCreated = useRef(false);
  const skyRightCreated = useRef(false);
  const billboardCreated = useRef(false);

  if (!mobile) {
    skyLeftDiv.current.id = adData.divIds.sky.left;
    skyRightDiv.current.id = adData.divIds.sky.right;
    billboardDiv.current.id = adData.divIds.billboard;
  }

  const handleCallback = useCallback((node) => {
    if (node) {
      Object.values(leaderDivs.current).forEach((leaderObj) => {
        const leaderObject = leaderObj;
        const { div: leaderDiv } = leaderObject;
        if (!leaderObject.created) {
          node.appendChild(leaderDiv);
          leaderObject.created = true;
        }
      });

      if (!skyLeftCreated.current && !mobile) {
        node.appendChild(skyLeftDiv.current);
        skyLeftCreated.current = true;
      }

      if (!skyRightCreated.current && !mobile) {
        node.appendChild(skyRightDiv.current);
        skyRightCreated.current = true;
      }

      if (!billboardCreated.current && !mobile) {
        node.appendChild(billboardDiv.current);
        billboardCreated.current = true;
      }
    }
  }, []);

  return (
    <AdContext.Provider
      value={{
        leaderDivs: leaderDivs.current,
        skyLeftDiv: skyLeftDiv.current,
        skyRightDiv: skyRightDiv.current,
        billboardDiv: billboardDiv.current,
        skyLeftCreated,
        skyRightCreated,
        billboardCreated,
      }}
    >
      {children}
      <div
        id="adrender"
        ref={handleCallback}
        style={{
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          width: 0,
          height: 0,
        }}
      />
    </AdContext.Provider>
  );
}

AdProvider.propTypes = {
  children: PropTypes.any,
};

AdProvider.defaultProps = {
  children: undefined,
};
