import React from "react";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { getString } from "../../utilities";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import GiftCards from "../global/giftCards";
import PromoField from "./promoField";

export default function PaymentFields({
  total,
  setTotal,
  setGiftCardNotApplied,
  giftCards,
  setGiftCards,
  hasPromoNotApplied,
  setHasPromoNotApplied,
  doesNotRequireCredit,
  promo,
  setPromo,
  planGroup,
  setCreditComplete,
  submitErrors,
  setSubmitErrors,
  isNewSubscriber,
}) {
  return (
    <>
      <div className="cta">{getString("subscribe.payment")}</div>

      <OptionallyDisplayed doDisplay={doesNotRequireCredit}>
        <div className="subscribe-required">{getString("subscribe.creditNotRequired")}</div>
      </OptionallyDisplayed>

      <OptionallyDisplayed doDisplay={isNewSubscriber || !doesNotRequireCredit}>
        <CardElement
          onChange={(data) => {
            setCreditComplete(data.complete);

            if (submitErrors) {
              setSubmitErrors(null);
            }
          }}
        />
      </OptionallyDisplayed>

      <GiftCards
        total={total}
        setTotal={setTotal}
        setGiftCardNotApplied={setGiftCardNotApplied}
        giftCards={giftCards}
        setGiftCards={setGiftCards}
      />

      {/* temporarily only allowing on family plans */}
      <OptionallyDisplayed doDisplay={planGroup && planGroup.toLowerCase() === "family"}>
        <PromoField
          planGroup={planGroup}
          promo={promo}
          setPromo={setPromo}
          hasPromoNotApplied={hasPromoNotApplied}
          setHasPromoNotApplied={setHasPromoNotApplied}
        />
      </OptionallyDisplayed>
    </>
  );
}

PaymentFields.propTypes = {
  total: PropTypes.number.isRequired,
  setTotal: PropTypes.func.isRequired,
  setGiftCardNotApplied: PropTypes.func.isRequired,
  giftCards: PropTypes.array.isRequired,
  setGiftCards: PropTypes.func.isRequired,
  hasPromoNotApplied: PropTypes.bool,
  setHasPromoNotApplied: PropTypes.func,
  promo: PropTypes.object,
  setPromo: PropTypes.func,
  planGroup: PropTypes.string.isRequired,
  doesNotRequireCredit: PropTypes.bool.isRequired,
  setCreditComplete: PropTypes.func.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,
  isNewSubscriber: PropTypes.bool,
};

PaymentFields.defaultProps = {
  hasPromoNotApplied: true,
  setHasPromoNotApplied: undefined,
  setPromo: undefined,
  promo: null,
  submitErrors: null,
  isNewSubscriber: false,
};
