import React from "react";
import strings from "../../strings/";
import { getAbcyaUrl, language } from "../../config";

export default function LanguageMenu() {
  return Object.keys(strings.footer.languageMenu).map((langKey) => {
    const stringValue = strings.footer.languageMenu[langKey];
    const url = new URL(`${getAbcyaUrl(langKey)}${language.resetHash}`);

    const setLangPreference = () => {
      try {
        window.localStorage.setItem(language.preferenceToken, langKey);
      } catch (e) {}
      window.location.replace(url);
    };

    return (
      <a data-testid={`footerLangLink_${langKey}`} role="link" key={stringValue} onClick={setLangPreference}>
        {stringValue}
      </a>
    );
  });
}
