import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import mbLogo from "../../../assets/img/minibannerLogo.jpg";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { leaveSiteHandler } from "../../redux/slices/leavingSiteSlice";

export default function ProductOffer({ title, caption, image, stores }) {
  const dispatch = useDispatch();

  const renderButtons = () => {
    const storeKeysArray = Object.keys(stores);
    return storeKeysArray.map((key, index) => {
      const buttonKey = `buttonKey${index}`;
      return (
        <button
          key={buttonKey}
          type="button"
          onClick={() => {
            dispatch(leaveSiteHandler({ url: stores[key] }));
          }}
          className="button-flat-color cyan round auto"
        >
          <span>{getString(`appStores.${key.toUpperCase()}`)}</span>
          <Arrow className="inline-arrow" />
        </button>
      );
    });
  };

  return (
    <div className="prod-offer-offer-wrapper">
      <h2>{title}</h2>
      <div className="prod-offer-offer-container">
        <img className="prod-offer-offer-image" src={image} width="200" height="200" alt={title} />
        <div className="prod-offer-offer-content">
          <h2>{title}</h2>
          <p>{caption}</p>
          <div className="prod-offer-offer-content-buttons">{renderButtons()}</div>
        </div>
      </div>
    </div>
  );
}

ProductOffer.propTypes = {
  /** Title string for the product offering. */
  title: PropTypes.string.isRequired,
  /** Object containing product offering URLs. */
  stores: PropTypes.object.isRequired,
  /** Caption/description string for the product offering. */
  caption: PropTypes.string,
  /** Image url for thumbnail/minibanner/icon artwork. */
  image: PropTypes.string,
};

ProductOffer.defaultProps = {
  caption: "",
  image: mbLogo,
};
