import React from "react";
import PropTypes from "prop-types";
import { getString } from "../../utilities";
import NoSymbol from "../../../assets/svg/noSymbol.svg";
import Lock from "../../../assets/svg/unlock.svg";
import Devices from "../../../assets/svg/devices.svg";
import SubscriptionCard from "./subscriptionCard";
import PlansGiftCardCTA from "./plansGiftCardCTA";
import PlansPromoDescription from "../global/plansPromoDescription";
import PlansPromoDisclaimer from "../global/plansPromoDisclaimer";

export default function FamilyPlans({ plan, setPlan, planAmount, setPlanAmount, planData, promo, setPromo }) {
  return (
    <div className="wrapper plans-wrapper family">
      <div className="plans-title">{getString("subscribe.plans.family.title.1")}</div>

      <PlansPromoDescription promo={promo} pRole="family" />

      <ul className="plans-perks">
        <li>
          <NoSymbol className="icon" />
          {getString("subscribe.plans.family.perks.0")}
        </li>
        <li>
          <Lock className="icon" />
          {getString("subscribe.plans.family.perks.1")}
        </li>
        <li>
          <Devices className="icon" />
          {getString("subscribe.plans.family.perks.2")}
        </li>
      </ul>

      <div className="plans-subs">
        <SubscriptionCard
          setPlan={setPlan}
          planAmount={planAmount}
          setPlanAmount={setPlanAmount}
          planData={planData}
          plan={plan}
          subscription="m1"
        />
        <SubscriptionCard
          setPlan={setPlan}
          planAmount={planAmount}
          setPlanAmount={setPlanAmount}
          planData={planData}
          plan={plan}
          subscription="m6"
        />
        <SubscriptionCard
          setPlan={setPlan}
          planAmount={planAmount}
          setPlanAmount={setPlanAmount}
          planData={planData}
          plan={plan}
          subscription="m12"
          isBest={true}
        />
      </div>

      <PlansGiftCardCTA promo={promo} setPromo={setPromo} />

      <PlansPromoDisclaimer promo={promo} pRole="family" />
    </div>
  );
}

FamilyPlans.propTypes = {
  plan: PropTypes.string.isRequired,
  setPlan: PropTypes.func.isRequired,
  planAmount: PropTypes.number,
  setPlanAmount: PropTypes.func,
  planData: PropTypes.object.isRequired,
  promo: PropTypes.object,
  setPromo: PropTypes.func.isRequired,
};

FamilyPlans.defaultProps = {
  promo: null,
  planAmount: undefined,
  setPlanAmount: undefined,
};
