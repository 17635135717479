import React from "react";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { getString } from "../../utilities";
import GiftCards from "../global/giftCards";

export default function PaymentFields({
  setGiftCardNotApplied,
  total,
  setTotal,
  giftCards,
  setGiftCards,
  setCreditComplete,
  submitErrors,
  setSubmitErrors,
}) {
  return (
    <>
      <div className="cta">{getString("manage.payment.credit")}</div>

      <CardElement
        onChange={(data) => {
          setCreditComplete(data.complete);

          if (submitErrors) {
            setSubmitErrors(null);
          }
        }}
      />

      <div className="cta">{getString("giftcard.redeem.1")}</div>

      <GiftCards
        setGiftCardNotApplied={setGiftCardNotApplied}
        total={total}
        setTotal={setTotal}
        giftCards={giftCards}
        setGiftCards={setGiftCards}
      />
    </>
  );
}

PaymentFields.propTypes = {
  setCreditComplete: PropTypes.func.isRequired,
  submitErrors: PropTypes.object,
  setSubmitErrors: PropTypes.func.isRequired,

  // giftCards > giftCardField
  setGiftCardNotApplied: PropTypes.func.isRequired, // manage>paymentInfo
  total: PropTypes.number.isRequired, // manage>paymentInfo
  setTotal: PropTypes.func.isRequired, // manage>paymentInfo
  giftCards: PropTypes.array.isRequired, // manage>paymentInfo
  setGiftCards: PropTypes.func.isRequired, // manage>paymentInfo
};

PaymentFields.defaultProps = {
  submitErrors: undefined,
};
